.person {
    max-width: 75em;
    padding: 0 0.5em;
}
.person article > header {
    margin: 1em 0;
    display: grid;
    grid-template-areas: "avatar title" "avatar about";
    grid-template-columns: min-content auto;
    grid-template-rows: min-content min-content auto;
    gap: 0.5em 1em;
}
.person .avatar {
    grid-area: avatar;
    width: 10em;
    height: 10em;
}
.person h1 {
    grid-area: title;
    margin: 0;
    align-self: center;
}
.person h1 small {
    font-weight: normal;
    font-style: italic;
    font-size: 0.6em;
}
.person h1 p {
    align-self: end;
}
.person .about {
    grid-area: about;
}
.person .person-ads {
    grid-area: ads;
    background-color: rgba(var(--neutral-values), 0.1);
    padding: 2em;
    margin: 2em 0 0 0;
    border-radius: var(--corner-radius);
}
.person-ads h3 {
    margin: 0;
}
.person .ads {
    padding: 1em 0;
}