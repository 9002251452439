.actions {
    grid-column: span 2;
    line-height: 1;
    display: flex;
    height: 1.75em;
    margin-top: 1em;
    position: absolute;
    bottom: 0.6em;
    left: 0.75em;
    width: calc(100% - 1.5em);
    flex-flow: row;
}
.actions button {
    background-color: transparent;
    text-align: left;
    color: var(--color-text-default);
    padding: 0;
    display: flex;
    align-items: center;
    margin: 0 2em 0 0;
}
.actions button:hover {
    box-shadow: none;
}

.actions button::before {
    content: '';
    margin: 0 0.5em 0 0;
    display: flex;
    align-items: center;
}
.actions button::before {
    content: url("../img/icons/heart.svg");
}
.actions button.like.selected::before {
    content: url("../img/icons/heart-full.svg");
}
.actions button.selected {
    color: var(--special-color);
}
.actions button.rating::before  {
    content: url("../img/icons/star.svg");
}
.actions button.share {
    margin: 0 0 0 auto;
}
.actions button.share::before  {
    content: url("../img/icons/share.svg");
    margin: 0;
}
.actions button.loading {
    opacity: 0.5;
    cursor: default;
}