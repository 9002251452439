*, *::before, *::after {
	box-sizing: border-box;
}
:root{

	--color-auati-lightblue: #5c9efc;
	--color-auati-pink: #da6fa6;
	--color-auati-yellow: #FFD633;
	--color-auati-red: #da5e54;
	--color-auati-blue: #4e6adc;
	--color-auati-green: #5c8fb3;
	--color-auati-purple: #4f64bf;

	--scrollbarBG: #272b61;
	--thumbBG: #4d599e;
	--scrollbarBorder: #272b61;

	--special-color-values: 57, 37, 141;
    --special-color: rgb(var(--special-color-values)); /* #4242af; */ 

    --special-bgcolor-values: 255, 210, 30;
    --special-bgcolor: rgb(var(--special-bgcolor-values));

	--border-width: 0.1em;
	--text-gray: #616161;


	--color-text-emphasis: #d65c99;
	--color-text-default: #191919;
	--color-text-gray: #575757;
	--color-text-label: #6A789D;

	--color-background: #f3f3f3;
	--color-surface: #fafafa;
	--color-alternate-bg: #e0e6eb;
	--color-selected-bg: #f5ffd0;

	--color-shadow: rgba(49, 45, 41, 0.2);

	--neutral-values: 120, 130, 140;
	
    --color-error-values: 218, 25, 25;
    --color-error-background: rgba(var(--color-error-values), 0.1);
    --color-error: #e22d4f;

    --color-success-values: 0, 100, 60;
    --color-success-background: rgba(var(--color-success-values), 0.2);
    --color-success: rgba(var(--color-success-values), 0.8);

    --color-info-values: 0, 60, 120;
    --color-info-background: rgba(var(--color-info-values), 0.2);
    --color-info: rgba(var(--color-info-values), 0.9);

	--color-rating-5-values:  20, 170, 100;
	--color-rating-4-values:  150, 190, 100;
	--color-rating-3-values:  220, 180, 40;
	--color-rating-2-values:  240, 130, 70;
	--color-rating-1-values:  240, 70, 100;

	--color-rating-5: rgba(var(--color-rating-5-values), 1);
	--color-rating-4: rgba(var(--color-rating-4-values), 1);
	--color-rating-3: rgba(var(--color-rating-3-values), 1);
	--color-rating-2: rgba(var(--color-rating-2-values), 1);
	--color-rating-1: rgba(var(--color-rating-1-values), 1);

	--corner-radius-small: 0.25em; 
	--corner-radius: 0.5em; 
	--corner-radius-big: 1em; 

	--elevation-shadow1: drop-shadow(1px 1px 0px rgba(0,0,0,0.125));
	--elevation-shadow2: drop-shadow(1px 1.5px 1px rgba(0,0,0,0.25));
	--elevation-shadow4: drop-shadow(2px 2px 2px rgba(0,0,0,0.25));
	--elevation-shadow8: drop-shadow(8px 8px 8px rgba(0,0,0,0.25));
	--elevation-shadow16: drop-shadow(16px 16px 16px rgba(0,0,0,0.25));

	--chat-color-bg: #EAE9DF; 
}
html {
	height: 100%;
}
#root {
	min-height: 100%;
	display: flex;
	flex-flow: column;
	align-items: stretch;
}
body {
	height: 100%;
	font: 16px Nunito, Roboto, Arial, Helvetica, sans-serif;
	font-weight: 500;
	background-color: var(--color-background);
	color: var(--color-text-default);;
}
main {
	display: flex;
    flex-flow: column;
	padding: 0em;
	height: 100%;
	max-width: 100%;
    width: 100%;
    margin: 0 auto;
}
#root>header,
#root>footer,
main>header,
main>section,
main>article,
main>h1 {
	width: 100%;
	align-self: center;
	animation: showing 0.5s ease-out;
	padding-left: calc(51% - 600px);
	padding-right: calc(51% - 600px);
}
main>h1 {
	margin-top: 2rem;;
	margin-bottom: 1rem;;
}
main>.buttons {
	padding: 1.5em 0.5em;
}

a {
	color: var(--special-color);
	text-decoration: none;
	font-weight: bold;
}
a:hover {
	text-decoration: underline;
}
b { 
	font-weight: 800;
}

h1 {
    font-size: 1.5em;
	text-transform: uppercase;
	color: var(--special-color);
	margin: 1em 0 0.25em 0;
	line-height: 1.1;
	/*
    color: #ffffff;
    margin: -0.3em 0 0 0;
    padding: 1.1em 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: -2em 0em 0 0em var(--special-color), 2em 0em 0 0em var(--special-color);
	*/
}
h1::before,
h1::after {
	content: "";
	width: calc(50% - 600px); 
}

h1>small {
	display: block;
	font-weight: normal;
	margin: 0.25em 0 0 0;

}

h1 .submit,
h1 button {
    font-size: 0.6em;
    margin: -0.5em 0 0 0;
    border: var(--border-width) solid rgba(255,255,255,0.3);
}
h2 {
	color: var(--special-color);
	font-size: 1.35em;
}
h3 {
	color: #616161;
	font-size: 1.3em;
}
h3 code {
	font-size: 1.1em;
	font-weight: bold;
	letter-spacing: 0.1em;
	padding: 0.5em 0.75em;
	background-color: #def;
	border: 2px dashed #abc;
	border-radius: 0.25em;
	margin: 0 0.25em;
	color: #293b54;
}
h4 {
	font-size: 1.25em;
	color: var(--color-text-label);
	margin: 1em 0 0.25em 0;
	font-weight: 500;
}
p {
	line-height: 1.35em;
	margin: 0.5em 0 1em 0;
}
/* b {
	padding: 0.5em;
	background-color: #FF0;
} */
figure {
	margin: 0px;
}
.card {
    border-radius: 0.35rem;
    background-color: var(--color-surface);
    filter: drop-shadow(1px 1px 2px var(--color-shadow));
    padding: 0.75em;
}
.hide {
	animation: toggling  0.4s ease-in forwards reverse;
}
.show {
	animation: toggling  0.4s ease-in backwards;
}

div.loading {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.75);
	display: flex;
	flex-flow: column;
    align-items: center;
    justify-content: center;
    animation: fadeIn .5s ease-out;
	box-shadow: 0 0.5em 1em rgba(0,0,0,1);
	color: #0d88c7;
}
.loading .waiting {
	text-shadow: 0 0 2em #ffffff;
	background-color: #fff;
	padding: 1em 2em;
	border-radius: 0.5em;
	border: 1px solid var(--special-color);
	animation: pulsar 3s infinite;
	margin: 1em;
}
.loading progress {
	-webkit-appearance: none; 
	appearance: none;
	width: 100%;
    height: 0.35em;
    border: none;
    position: absolute;
	top: 0;
	left: 0;
	animation: 1s pulse infinite alternate ease-in-out;
}
.loading progress::-webkit-progress-bar {
	background-color: #cee0e9;
}
.loading progress::-webkit-progress-value {
	background-color: var(--special-color);
}
.loading .upload-progress p {
	font-size: 1.3em;
	border-radius: 50%;
	height: 4em;
	width: 4em;
	background-color: var(--special-color);
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.loading .upload-progress .raise {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.35);
	transition: 0.5s;
	animation: pulse 1s infinite alternate ease-in-out;
}
article .item {
	margin: 0 0 1.5em 0;
}
article .item:last-child {
	margin: 0;
}
article p {
	margin: 0.125em 0 0 0;
}
article .fotos {
	overflow-x: auto;
	display: flex;
	margin: 0 -1em;
	padding: 0 1em;
	position: relative;
	transform: perspective(0);
	-webkit-mask-image: linear-gradient(to right, transparent, white 1.3em, white calc(100% - 1.3em), transparent);
}	
article .fotos::after {
	content: "x";
	color: transparent;
	width: 0.5em;
	margin: 0 -0.5em;
}
article .fotos img {
	height: 10em;
	margin: 0.5em 1em 0.5em 0;
	border-radius: 0.4em;
	box-shadow: 0 0 0 var(--border-width) var(--special-color);
}
article .item.textarea p {
	background-color: #EEF4F6;
	padding: 0.75em 1.25em;
	border-radius: 0.5em;
	margin: 0.5em -0.1em 1em -0.1em;
	white-space: pre-line;
}
.grecaptcha-badge {
    transform: scale(0);
}
em {
	color: var(--color-text-emphasis);
	font-style: normal;
}
.error {
	color: var(--color-error);
}
p.error {
	border: 1px solid var(--color-error);
	background-color: var(--color-error-background);    
	padding: 1rem;
	font-style: italic;
	border-radius: 0.5rem;
}
dl {
	border: 1px solid gray;
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: var(--color-alternate-bg);
}
dt {
	margin-top: 1em;
	font-weight: 800;
	opacity: 0.6;
}
dt:first-child {
	margin-top: 0;
}
dd {
	font-size: 1.1em;
	margin: 0 0 1em 0;
}
dd:last-child {
	margin-bottom: 0;
}
.categories {
	text-align: center;
	display: flex;
	flex-flow: row wrap;
	gap: 0.5em;
	padding: 0.5em 0;
}
.categories ul {
	list-style: none;
	padding: 1em 0 0.5em 0;
	width: 100%;
}
.categories li {
	background-color: var(--color-alternate-bg);
	padding: 0.5em;
	border-radius: 0.5em;
	font-size: .7em;
	cursor: pointer;
}
.categories figure {
	width: 9em;
}
.categories li:hover {
	opacity: 1;
	filter: var(--elevation-shadow4);
}
.categories img {
	aspect-ratio: 1 / 1;
	width: 40%;
	border-radius: 50%;
	overflow: hidden;
}
.categories .unselected {
	opacity: 0.9;
	filter: saturate(1);
	transition: all 0.25s;
}
.categories li.unselected:hover {
	filter: var(--elevation-shadow4);
	opacity: 1;
	filter: saturate(1.5);
}
.categories .selected {
	opacity: 1;
	filter: var(--elevation-shadow2);
	font-weight: bold;
	background-color: var(--color-selected-bg);
}
.field.choice p {
	margin: 0 0 0.5em 0;
}
main.login section {
	display: flex;
	flex-flow: column;
	text-align: center;
}
main.login form {
	max-width: 49em;
	align-self: center;
	text-align: left;
}
main.login .fields {
	flex-flow: row wrap;
}
main.login footer {
	display: flex;
	justify-content: center;
}
main.login .buttons {
	flex-flow: row wrap;
	justify-content: center;
	max-width: 26em;
}
main.login button[type='submit'] {
	align-self: center;
	margin: 0 10%;
	flex: 1 0 80%;
}
main.login .link {
	margin: 0 auto;
	margin-top: 2em;
}
main.category>header {
	display: flex;
    align-items: center;
    margin: 1em 0;
	overflow: hidden;
}
main.category h1 {
	margin: 0;
	color: var(--color-background);
	filter: drop-shadow(0px 0px 2em black);
}
main.category header>figure {
	background-color: var(--color-background);
	border-radius: 50%;
	margin: 1em 1em 1em 0;
	overflow: hidden;
	aspect-ratio: 1 / 1;
	filter: drop-shadow(0px 0px 6em black);
}
main.category figure img {
	width: 4.5em;
}
.cookie-policy {
	position: fixed;
	bottom: 0.5em;
	color: var(--color-background);
	background-color: var(--special-color);
	padding: 1rem 1.125rem;
	border-radius: var(--corner-radius-big);
	margin: 1rem 0;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 2rem;
	left: calc(4rem + 10%);
	width: calc(80% - 8rem);
	z-index: 2;
}
.cookie-policy a {
	color: inherit;
	font-weight: bold;
}