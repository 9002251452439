@keyframes openning-dialog {
	0% {
		opacity: 0;
		margin-top: 50%;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		margin-top: 0%;
		transform: scale(1);
	}
}
@keyframes closing-dialog {
	0% {
		opacity: 1;
		margin-top: 0%;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		margin-top: 50%;
		transform: scale(0.8);
	}
}
@keyframes openning-dialog-bg {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes closing-dialog-bg {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.dialog {
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	animation: openning-dialog-bg 0.3s ease-out;
}
.dialog.closing {
	animation: closing-dialog-bg 0.5s ease-in forwards;
}
.dialog .bg {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(143 157 207 / 85%);
	z-index: 2;
	backdrop-filter: blur(4px);
}
.dialog .window {
	background-color: #fff;
	border-radius: 0.5em;
	z-index: 3;
	box-shadow: 0 0 0 0.1em #8FABBA, 0 0 3em -1em #8FABBA;
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: stretch;
	width: calc(7% + 23em);
    max-width: 80%;
	max-height: 99%;
	animation: openning-dialog 0.3s cubic-bezier(.49,1.48,.81,.98);
	transition: 0.25s all;
}
.dialog.closing .window {
	animation: closing-dialog 0.3s cubic-bezier(.49,1.48,.81,.98) forwards;
}
.dialog header {
    height: 4.7rem;
    display: flex;
    align-items: center;
	justify-content: space-between;
}
.dialog header h3 {
	width: auto;
	font-style: normal;
	font-weight: bold;
	font-size: 1.3em;
	color: var(--special-color);
	text-align: left;
	margin: 0 0 0 1em;
	line-height: 1;
}
button[type="button"].close,
button.close {
	background: transparent;
	color: #909090;
	font-weight: normal;
	font-size: 1.5em;
	padding: 0.5em 0.75em;
	margin: 0;
	box-shadow: none;
	border: none;
	width: auto;
}
button.close::before {
	content: "\2715";
	display: block;
	font-weight: 100;
}
button.close:hover {
	box-shadow: none;
	transform: none;
	color: #333333;
}

.dialog .content {
	overflow: auto;
	padding: 0.2em 1.4em 1.4em 1.4em;
    border-radius: var(--corner-radius);
}
.dialog .content section p {
	border-radius: 0.5em;
	display: flex;
	flex-flow: column;
}
.dialog .content section p span {
    background-color: rgba(255,255,255,0.9);
    padding: 1em 1.5em;
    border-radius: 0.25em;
}
.dialog .field {
	width: 100%;
}
.dialog form .field+.field{
	margin-left: 0;
}
.dialog form p {
	text-align: center;
    color: var(--color-info);
    /* background-color: var(--color-info-background); */
    padding: 0.75em 1em;
    border-radius: 0.2em;
	width: 100%;
}
.dialog form .fields {
	background-color: rgba(var(--neutral-values), 0.075);
    padding: 1.4em;
    box-shadow: 0 0 0 1px rgba(var(--neutral-values), 0.5);
    border-radius: var(--corner-radius);
	gap: 0.5em;
}
.dialog form .field p {
	text-align: left;
    padding: 0;
	margin: 0.25em 0 0 0;
}
.dialog footer {
	display: flex;
	flex-flow: column;
    align-items: stretchch;
}
.dialog footer .buttons {
	display: flex;
	flex-flow: column;
    align-items: flex-end;
    align-items: center;
    margin: 0.25em 0 1em 0;
}
.dialog button[type="submit"] {
	align-self: center;
	font-size: 1.1em;
	margin: 0;
    width: 100%;
}
.dialog .buttons button[type='button'] {
	border: none;
	padding: 0;
	margin: 1em 0 0 0;
}

.dialog input {
}
.dialog.responseDialog .preview button {
	width: 100%;
	margin: 1em 0 0 0;
}
.dialog.forget h4 {
	margin: 0 0 1em 0;
	text-align: center;
}
.dialog.forget p {
	margin: 0 0 2em 0;
	padding: 0;
}
.dialog.forget .waiting {
	height: 12em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column nowrap;
}
.dialog.forget .waiting img {
	width: 4em;
	margin-bottom: 1em;
}
.dialog form fieldset {
	margin: 0;
	border: none;
	padding: 0;
}
.dialog form fieldset legend {
	margin: 0 0 1em 0;
	padding: 0;
}
.dialog form fieldset + .buttons {
	margin: 0.5em 0 0 0;
}
.dialog .illustration {
	text-align: center;
}
.dialog .illustration + h2 {
    font-size: 1.3em;
    margin: 0 0 1em 0;
    text-align: center;
}