
.avatar .loading {
	background: var(--color-bg-half) url('/images/loading.gif') no-repeat center / 50%;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
}
.avatar .edit {
	background: #91ace3 url('../img/icons/edit.svg') no-repeat center / 67%;
    border-radius: 50%;
    position: absolute;
    width: 0.25em;
    height: 0.25em;
    bottom: 0;
    right: 0;
    filter: invert(1) brightness(1.5) hue-rotate(90deg) saturate(2.5);
}

.avatar {
	border-radius: 50%;
	position: relative;
	width: 1em;
	height: 1em;
	font-weight: 400;
	color: var(--color-auati-yellow);
    background-color: var(--color-auati-purple);
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar.pointer {
	cursor: pointer;
}
figure.avatar > * {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.avatar > span {
	display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5em;
    background-color: var(--color-fg);
    border-radius: 50%;
    color: var(--color-surface-75);
}


.avatar img {
	background-color: var(--color-bg);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	color: var(--color-bg-double);
}

.card .avatar {
	justify-self: end;
}
.seephoto {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	overflow: auto;
	z-index: 1;
	background-color: var(--color-surface);
	display: flex;
    align-items: center;
}
.seephoto img {
	border-radius: 0.25em;
	max-width: 100%;
	max-height: 100%;
}
.seephoto.magnified img {
	max-width: unset;
	max-height: unset;
}


.photo .avatar {
	font-size: 8em;
    margin: 0 auto;
}

.myaccount .avatar {
    font-size: 10em;
}