article h1 {
    font-size: 2em;
    margin: 1.25em 0;
}
article h2 {
    margin: 2em 0 1em 0;
}
article p {
    margin: 1em 0;
}
article .abstract {
    font-size: 1.2em;
    font-style: italic;
}
article footer {
    margin: 4em 0;
    font-size: 0.8em;
    text-align: center;
}
article footer img {
    width: calc(10em + 5%);
}
article .horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: 1em;
}
article .horizontal .card {
    border-radius: var(--corner-radius);
    background-color: #fff;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}
article .horizontal .card img {
    max-width: 8em;
    max-height: 6em;
}
.img-paragraph {
    width: 50%;
    float: left;
    margin: 0 1em 0.5em 0;
}