.auatis {
    display: flex;
    flex-flow: column nowrap;
    margin: 1em 0;
    gap: 2em;
}
.auatis li {
    display: flex;
    flex-flow: row nowrap;
    gap: 1em;
}
.auatis li .posters {
    width: 40%;
    max-width: 24rem;
    display: flex;
    flex: 0 0 40%;
    gap: 1em;
}
.auatis li figure {
    display: flex;
    flex-flow: column nowrap;
    width: calc(50% - 0.5em);
    gap: 0.5em;
}
.auatis figcaption {
    font-weight: 600;
}
.auatis li figure>div {
    aspect-ratio: 4/3;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: 0 0 1px 1px var(--color-shadow);
    border-radius: var(--corner-radius-small);
    background-color: var(--color-alternate-bg);
}
.auatis li figure img {
    width: 100%;
    border-radius: inherit;
}
.auatis figure span {
    text-align: center;
    font-size: 0.8em;
    padding: 1em;
    font-style: italic;
    color: var(--color-text-label);
}
.auatis li .information {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}
.auatis p {
    margin: 0;
}
.auatis h2 {
    margin: 0;
    font-size: 1.25em;
}
.auatis button.messages {
    margin: auto 0 0 0;
}
.auatis .last-message {
    font-style: italic;
    opacity: 0.8;
    font-size: 0.85em;
    line-height: 1.2;
    margin: 0.5em 0 1em 0;
    border-radius: var(--corner-radius);
}
.auatis .last-message b {
    font-weight: 700;
}