#root header.top {
	display: flex;
	justify-content: center;
	padding-top: 0.5em;
	padding-bottom: 1em;
	margin: 0;
	align-items: center;
	justify-content: space-between;
	overflow: visible;
	gap: 0.5em;

}
header.top .width {
	color: var(--special-color);
	display: flex;
	width: 100%;
	max-width: 1200px;
}
.top h3 {
	max-width: 6em;
    font-style: italic;
    font-size: 1.8em;
    margin: 0 0 0 0.75em;
    line-height: 1em;
    margin-right: auto;
    color: inherit;
	font-weight: bold;
}
.top .logo {
	width: calc(8% + 6.5em);
	min-width: 7em;
	height: inherit;
}
.top .logo img {
	width: 100%;
}
.top .settings {
	position: relative;
}
.top button.user-logged {
    padding: 0;
    width: 3em;
    height: 3em;
	border-radius: 50%;
	color: var(--special-color);
	background-color: var(--special-bgcolor);
}
.top button.user-logged.admin {
	padding: 0;
    width: 3em;
    height: 3em;
    border-radius: 50%;
	background-color: #295989;
}
.top button.user-logged.editor {
	padding: 0;
    width: 3em;
    height: 3em;
    border-radius: 50%;
	background-color: #68bcdb;
}
.search {
	display: flex;
	flex-flow: row;
	align-items: stretch;
	align-content: stretch;
	position: relative;
	flex: 1;
}
.search input {
	background-image: url('../img/lupa.svg');
	background-repeat: no-repeat;
	background-position: calc(100% - 0.9em);
	background-size: auto 55%;
	background-blend-mode: hard-light;
    border-radius: 3em;
    padding: 0.8em;
	text-indent: 0.25em;
	font-size: 1.2em;
}
.search input:hover,
.search input:focus-visible,
.search input:focus {
	background-image: none;
	outline: 2px solid var(--special-bgcolor);
}
.filter {
	display: flex;
	margin: 0 1em 0 0;
}
form.search:invalid button {
    background-color: transparent;
}
.search input:invalid {
    box-shadow: inset 0 0 0 0.075em rgba(var(--neutral-values), 0.1);
    background-color: rgba(var(--neutral-values), 0.1);
    color: #000000;
}
.explore .search input:invalid {
    background-color: var(--color-background);
}