main.consult {
	display: flex;
	justify-content: center;
}
.consult section {
	text-align: center;
	margin: auto;
	padding: 1.5em;
}
.consult h2 {
	display: flex;
	flex-flow: column;
	align-items: center;
	color: #000;
	font-size: 1.6em;
	margin: 0;
}
.consult h3 {
	font-weight: normal;
	font-size: 1.3em;
	color: #616161;
	margin: 0.25em 0 1.5em 0;
}
.consult h2::before {
	content: "";
	width: 2.5em;
	height: 2.5em;
	margin-bottom: 1em;
	display: block;
	/* background: transparent url('../img/lupa.svg') center center/contain; */
}
