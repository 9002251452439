form {
	display: flex;
	flex-flow: column;
	line-height: 1.15;
	gap: 1.25em;
}
h1 + form {
	margin-top: 2em;
	gap: 1.5em;
    background: var(--color-surface);
    padding: 2em 2.25em;
    border-radius: var(--corner-radius-big);
	border: 1px solid var(--color-alternate-bg);
	filter: var(--elevation-shadow1);
}
a,
input,
select,
textarea,
button {
	border: none;
	font: inherit;
	margin: 0;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
form p {
	line-height: 1.15;
}
input, select, textarea {
	border-radius: 0.25em;
	box-shadow: inset 0 0 0 0.075em rgba(var(--neutral-values), 0.75);
	background-color: #EEF4F6;
    padding: 0.7em var(--border-width);
    text-indent: 0.9em;
    width: 100%;
    color: #000;
}
input[type="file"] {
	text-indent: 0;
}

::placeholder { 
	color: #7B98A7;
	opacity: 1; 
}
:-ms-input-placeholder { 
	color: #7B98A7;
}
::-ms-input-placeholder { 
	color: #7B98A7;
}
input:focus::placeholder, 
select:focus::placeholder, 
textarea:focus::placeholder {
	color: #667b83;
}
textarea {
	resize: vertical;
	width: 100%;
	min-height: 8em;
	text-indent: 0em;
	padding: 0.7em 1.1em;
}
.fields {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 1em 0;
	gap: 2em;
	flex-flow: column;
}
.fields:last-child {
	margin: 0;
}
form .field {
	display: flex;
	flex-flow: column;
    flex: 1;
}
.field>label {
	display: inline-block;
	margin: 0.5em 0.5em 0.25em 0em;
	font-weight: 600;
}
.field p {
	font-size: 0.9em;
	margin: 0.5em 0 0 0;
	color: var(--color-text-gray);
}
.textarea+.field:nth-of-type(2n) {
	margin-left: 0em;
}
.field>div {
	display: flex;
	align-items: center;
}
input:focus, 
select:focus, 
textarea:focus {
	box-shadow: inset 0 0 0 0.075em #BCD3E9;
	background-color: #F7FBFD;
}
input:invalid,
select:invalid,
textarea:invalid {
	box-shadow: inset 0 0 0 0.075em #E57373;
    background-color: #fdf1f5;
    color: #bf2e4f;
}
input:empty, 
select:empty, 
textarea:empty {
	box-shadow: inset 0 0 0 0.075em rgba(var(--neutral-values), 0.75);
	background-color: #EEF4F6;
}
input:disabled,
textarea:disabled,
select:disabled {
	color: rgba(81, 81, 81, 0.69);
    background-color: rgb(218, 218, 218);
	text-shadow: 1px 1px #fff;
    box-shadow: inset 0 0 0 0.075em #cdcdcd;
}
input[type="file" i] {
	display: flex;
    flex-flow: column;
    padding: 0 0 0 1em;
}
button {
	margin: 0.375em 0;
	display: inline-block;
	background-repeat: no-repeat;
}
.submit,
.button,
::-webkit-file-upload-button,
button {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	border-radius: 0.4em;
    color:  var(--special-color);
	font-weight: bold;
	font-size: inherit;
	cursor: pointer;
	transition: all 0.1s ease-out;
	display: inline-flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	box-shadow: 
		0 0.01em 0em rgba(0,0,0,0.01),
		0 0em 0.5em -1.25em rgba(0,0,0,1);
}
.button,
button[type="button"] {
	background-color: transparent;
	padding: 0.5em 1em;
	box-shadow:  
		0 0.01em 0em rgba(0,0,0,0.01), 
		0 0em 0.5em -1.25em rgba(0,0,0,1), 
		0 0 0 1px rgba(var(--special-color-values), 0.5) inset
	;
}
.submit,
button[type="submit"] {
	background-color: var(--special-bgcolor);
	padding: 0.75em 1.2em;
	border: none;
}
::-webkit-file-upload-button {
	padding: 0.5em 0.6em;
    font-size: 0.9em;
    margin: 0.5em -0.5em;
}

button.link {
	color: var(--special-color);
	font: inherit;
	font-weight: bold;
	box-shadow: none;
	transform: none;
	transition: none; 
	margin: 0;
	padding: 0;
	background-color: transparent;
}

.button:hover,
button:hover {
	box-shadow: 0 0.25em 1em rgb(0 0 0 / 5%), 0 1em 0.6em -1em rgba(0 0 0 / 15%);
	transform: translate(0, -0.05em) scale(1.02);
	transition: all 0.1s ease-out; 
}
button[type='button']:hover,
.button:hover {
	box-shadow: 0 0.25em 1em rgb(0 0 0 / 5%), 
	0 1em 0.6em -1em rgba(0 0 0 / 15%),
	0 0 0 2px rgba(var(--special-color-values), 0.25);
}
.button:focus,
button:focus {
	outline: none;
}
button[type='button']:active {
	box-shadow: 0 0.2em 0.5em rgba(0,0,0,0.05), 
	0 0.75em 1.1em -1.1em rgba(0,0,0,1),
	0 0 0 2px rgba(var(--special-color-values), 0.25);
}
.button:active,
button:active {
	box-shadow: 0 0.2em 0.5em rgba(0,0,0,0.05), 0 0.75em 1.1em -1.1em rgba(0,0,0,1);
    transform: translate(0, 0em) scale(1.01);
    filter: brightness(1.1);
    transition: all 0.1s ease-out;
}

button.link:hover {
	text-decoration: underline;
	box-shadow: none;
}

form:invalid button[type="submit"]:hover {
	box-shadow: none;
	transform: none;
	transition: none; 
	cursor: default;
}
form:invalid button[type="submit"],
.button:disabled,
button:disabled {
	transform: translate(0, -0.1em) scale(1.02);
    filter: brightness(1.1);
    transition: all 0.1s ease-out;
    background-color: rgb(133 133 133 / 19%);
    color: rgb(69 69 69 / 55%);
    text-shadow: 1px 1px 0 #ffffffb5;
    box-shadow: none;
    cursor: default;
	border: none;
}
.buttons {
	display: flex;
	justify-content: flex-end;
	overflow: visible;
	flex-flow: column;
}
.buttons::before {
	content: "";
}
.buttons .submt,
.buttons button[type="submit"] {
	align-self: flex-end;
	flex: 1;
}
button.send {
	display: flex;
	align-items: center;
}
button.send::before {
	content: "";
	/*background: transparent url('../img/enviar.svg') center / contain;*/
	width: 1.25em;
	height: 1.25em;
	margin: 0 0.5em 0 0;
}
button.accept {
	display: flex;
	align-items: center;
}
button.accept::before {
	content: "";
	/* background: transparent url('../img/status/procedente.svg') center / contain; */
	filter: brightness(10);
	width: 1.25em;
	height: 1.25em;
	margin: 0 0.5em 0 0;
}
.bad, button.bad {
	background-color: var(--color-error);
	color: var(--color-background);
	border: none;
}
.good, button.good {
	background-color: var(--color-success);
	color: var(--color-background);
	border: none;
}
button.togglepass {
	width: 2.7em;
	height: 2.7em;
	margin: 0;
	background: url('../img/icons/eye-off.svg') no-repeat center / 50%;
	background-blend-mode: lighten;
}
button.togglepass.text {
	background-image: url('../img/icons/eye.svg');
}
.field.check,
.field.radio {
	flex-flow: row wrap;
	align-items: center;
}
.field.radio label {
	width: 100%;
	display: flex;
}
.field.radio p {
	margin: 0.5em 0;
	flex: 0 0 100%;
}
.field.check label {
	flex: 1 0 0;
}
.field.check p {
	flex: 0 1 1;
	width: 100%;
}
input[type='checkbox'],
input[type='radio'] {
	margin-right: 1em;
	width: 1.25em;
	height: 1.25em;
	box-shadow: none;
	flex: 0 0 1;
	vertical-align: middle;
}
label > input + span,
input + label {
	font-weight: 500;
	flex: 1 1 0;
	vertical-align: middle;
}
label > input:checked + span,
input:checked + label {
	font-weight: 700;
}
fieldset {
	max-width: 1200px;
    border-radius: 0.5em;
    padding: 1.5em 1.4em 1.5em;
    margin: 1em auto;
    border: var(--border-width) solid var(--special-color);
    margin: 2em 0 .5em 0;
}
form:invalid fieldset {
    border: 1px solid #E57373;
}
legend {
	font-size: 1.4em;
	padding: 0 0.5em;
	color: var(--special-color);
	line-height: 1;
	margin: 0 -0.5em;
}
legend+p {
	margin-top: 0
}
form .field.textarea {
	width: 100%;
	margin-left: 0;
}
form .field.titulo,
form .field.papel,
form .field.classificacao {
	margin-left: 0;
	width: 100%;
}
.field.dataacontecimento:nth-of-type(2n),
.field.titulo:nth-of-type(2n),
.field.classificacao:nth-of-type(2n) {
	margin-left: 0;
}
.field.id_tipo {
	margin-left: 1em;
}
.files {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.files figure {
	margin: 0 0.5em 0.5em 0;
	padding: 0.5em;
	background-color: #e7ecf1;
	border-radius: .4em;
	display: flex;
	flex-flow: column;
	align-items: center;
}
.files img {
	border-radius: .3em;
	height: 10em;
}
.files figcaption {
	font-size: 0.9em;
	color: #5a5e63;
	text-align: center;
	margin: 0.5em 0 0 0;
}
.buttongroup {
	width: 100%;
	margin: 1.5em 0;
	gap: 1em;
	display: flex;
}
.field.buttongroup>div {
	display: flex;
	flex-flow: wrap;
}
.buttongroup button {
    flex-flow: row;
	flex: 1;
    display: flex;
    white-space: nowrap;
    align-items: center;
    margin: 0 1em 1em 0;
}
.buttongroup.choice button {
	margin: 0em;
}
.buttongroup button img {
	height: 1.5em;
	margin: 0 0.75em 0 0;
}
.buttongroup button.selected {
	border-width: 0.2em;
	filter: contrast(2) brightness(1.5);
	box-shadow: 0 0 0 0.3em #fff;
}
.buttongroup button.selected::before {
	content: '✔️ ';
	filter: brightness(5);
	margin: 0 0.75em 0 0;
}


.field.password {
	flex-flow: row wrap;
	justify-content: space-between;
}
.field.password label {
	width: 100%;
}
.field.password:last-of-type input,
.field.password input:last-of-type {	
	width: calc(100% - 4em);
}
.fields > small {
	width: 100%;
}
.inputimage ul {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 1em;
	flex-flow: row wrap;
	padding: 0.75em 0 0.5em 0;
}
.inputimage li {
	padding: 0;
	border: 1px dashed var(--color-text-gray);
	border-radius: 0.5em;
	text-align: center;
	background-color: rgba(var(--color-info-values), 0.05);
	position: relative;
	width: calc(33.3% - 1em);
	aspect-ratio: 4 / 3;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
}
.inputimage.poster li {
	aspect-ratio: 4 / 3;
	max-width: unset;
	width: 60%;
}
.inputimage li span {
    font-weight: 300;
    font-style: italic;
    font-size: 0.9em;
	color: var(--color-text-gray);
}
.inputimage .choice {
	padding: 0.5em;
}
.inputimage .choice button {
	font-size: 0.8em;
	background-color: var(--color-surface);
	margin: 0;
}
.hidden,
.field.choice input,
.inputimage input {
	opacity: 0;
	position: absolute;
	z-index: -1; 
    width: 20px;
    height: 1px;
    padding: 0;
}
.inputimage figure {
	width: 100%;
	height: 100%;
	border-radius: inherit;
}
.inputimage img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: inherit;
}
.inputimage .remove {
	position: absolute;
	width: 1.5em;
	height: 1.5em;
	background-color: rgba(var(--neutral-values), 1);
	right: -0.5em;
	top: -0.5em;
	margin: 0;
	padding: 0;
	color: var(--color-background);
	border-radius: 50%;
}
.inputimage .remove:hover {
	transform: scale(140%);
	background-color: var(--color-error);
	box-shadow: none;
}

span.required {
    color: red;
}

span.required::before {
    content: "* ";
}
