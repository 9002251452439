@keyframes showing {
	0% {
		opacity: 0;
		transform: translateY(-2%) scaleY(0.98);
	}
	100% {
		opacity: 1;
		transform: translateY(0) scaleY(1);
	}	
}
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }	
}
@keyframes pulse {
	from { opacity: 0.5; }
	to { opacity: 1; }	
}
@keyframes hidding {
	0% {
		background-color: red;
	}
	100% {
		background-color: yellow;
	}
}
@keyframes toggling {
	0% {
		opacity: 0;
		max-height: 0;
		margin: 0;
		transform: scaleY(0.9);
	}
	99% {
		max-height: 50vh;
		transform: scaleY(1);
	}
	100% {
		opacity: 1;
		max-height: initial;
	}
}
@keyframes pulsar {
	0% {
		box-shadow: 0 0.0em 1em -0.5em rgba(0,0,0,0);
		transform: scale(1) translateY(0);
	}
	40% {
		box-shadow: 0 0.0em 1em -0.5em rgba(0,0,0,0);
		transform: scale(1) translateY(0);
	}
	70% {
		box-shadow: 0 0.5em 1em -0.5em rgba(0,0,0,0.5);
		transform: scale(1.1) translateY(-0.5em);
	}
	100% {
		box-shadow: 0 0.0em 1em -0.5em rgba(0,0,0,0);
		transform: scale(1) translateY(0);
	}
}