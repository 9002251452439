@media (max-width: 1200px) {
	#root>header,
	#root>footer,
	main>header,
	main>section,
	main>article,
	main>h1 {
		padding: 0 1rem;;
	}
	.instrucoes li {
	}
	.destaque {
		padding-bottom: 0;
		height: 41.5em;
		background-size: contain;
	}
	.destaque div {
		background-size: contain;
	}
	section.video {
		padding: 1em;
	}
	section.impacto {
		padding: 1em;
	}
	.instrucoes li {
		width: 50%;
		padding: 1em calc(0.5em + 5%);
	}
	.ad article {
		gap: calc(4% + 1em)
	}
}

@media (max-width: 960px) {
	.ads {
		column-count: 2;
		grid-template-columns: repeat(2, 1fr);
	}
	.ad article {
		grid-template-columns: repeat(2, calc(50% - 12em)) auto;
	}
	.cookie-policy {
		left: calc(2rem + 10%);
		width: calc(80% - 4rem);
	}
}

@media (max-width: 860px) {
	body {
		font-size: 17px;
	}
	#root>header {
		font-size: 0.92em;
	}
	#root main {
		overflow: initial;
	}
	.top .search {
		width: 100%;
	}
	.top .search input {
		padding: 0.9em;
		margin: 0;
	}
	.top nav {
		/* order: 3; */
	}
	main {
		overflow: unset;
	}
	h3 {
		text-align: center;
	}
	.ad article {
		grid-template-areas: 
			'poster' 
			'summary'
			'moreinfo'
			'rating' 
			'grades'
			'reviews'
			'professional'
		;
		grid-template-columns: auto;
		grid-gap: 1em;
		gap: 1em;
	}
	.summary {
		display: grid;
		grid-template-areas: 
			'h1 about'
			'address about'
			'offer about'
			'want about'
			'. about'
			'auati about'
		;
		grid-template-columns: repeat(2, calc(50% - 1.5em));
		gap: 0em 3em;
	}
	.summary h1 { grid-area: h1; }
	.summary address { grid-area: address; }
	.summary .offer { grid-area: offer; }
	.summary .want { grid-area: want; }
	.summary .auati { grid-area: auati; }
	.ad .summary button[type='submit'] { grid-area: auati;}
	.ad .auati { grid-area: auati;}
	.summary .professional { grid-area: about; }
	.person article > header {
		grid-template-areas: 
			"avatar"
			"title"
			"about"
		;
		grid-template-columns: auto;
		justify-items: center;
		text-align: center;
	}
	.person .about {
		width: 80%;
		min-width: 16rem;
	}
	.person .person-ads {
		padding: 1em calc(0.5em + 3%);
	}
	.cookie-policy {
		left: calc(1rem + 5%);
		width: calc(90% - 2rem);
	}
}

@media (max-width: 740px) {
	.summary {
		display: flex;
		flex-flow: column nowrap;
	}
	.ad .auati.requester {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0.5em;
		filter: var(--elevation-shadow16);
		z-index: 1;
	}
}

@media (max-width: 580px) {
	body {
		font-size: 18px;
	}
	body.dialoguing {
		overflow: hidden;
	}
	header.top {
		flex-wrap: wrap;
		padding-top: 0;
	}
	.top .logo {
		justify-self: center;
		align-self: center;
		margin-right: auto;
		width: calc(15% + 5em);
	}
	.top .search {
		flex: 1 1 100%;
		width: 100%;
		order: 4;
		min-width: unset;
		margin: 0;
	}
	.top .search input {
		margin: 0.25em;
		width: calc(100% + 0.5em);
		padding: 0.7em 0.9em;
	}
	.top nav {
		margin: 0;
		width: calc(100vw - 1em);
		top: 3em;
		left: calc(-100vw + 3.4em);
	}
	nav ul {
		margin: 0;
		padding: 0;
		justify-content: space-between;
	}
	.start {
		flex-flow: column;
	}
	.impacto {
		max-width: unset;
	}
	.instrucoes li {
		width: 100%;
		padding: 1em calc(0.5em + 10%);
	}
	.destaque {
		flex: 0 0 35%;
	}
	.first-message {
		flex-wrap: wrap;
	}
	.start .first-message h1 {
		order: 1;
		flex: 0 1 90%;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
	.start .first-message figure {
		order: 0;
		padding: 0;
	}
	.start .categories {
		font-size: 0.8em;
	}
	.ads {
		column-count: 1;
		grid-template-columns: repeat(1, 1fr);
	}
	.buttons {
		display: flex;
		flex-flow: row wrap;
	}
	.button,
	::-webkit-file-upload-button,
	button {
		width: 100%;
	}
	.dialog .window {
		width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;
		border-radius: 0;
		transition: all 0.25s;
	}
	.ad article {
		padding: 1em;
		grid-template-areas:
			'poster' 
			'summary'
			'moreinfo'
			'rating' 
			'grades'
			'reviews'
			'professional'
		;
		grid-template-columns: auto;
		grid-gap: 1em;
		gap: 1em;
	}
	.ad .actions {
		font-size: 1.1em;
	}
	.ad .actions button::before {
		transform: scale(1.2) translate(0,0.1em);
	}
	.ad .professional {
		grid-area: professional;
	}
	.cookie-policy {
		left: 1rem;
		width: calc(100% - 2rem);
		flex-flow: column;
		text-align: center;
	}
	.auatis li {
		flex-flow: column;
	}
	.auatis li .posters {
		width: 100%;
		max-width: unset;
	}
}

@media (max-width: 480px) {
	.img-paragraph {
		width: 100%;
		float: none;
		margin: 0.5em 0;
	}
}

@media (max-width: 321px) {
	.top nav {
		width: 290px;
		left: -245px;
	}
}