.tabs {
    display: flex;
    flex-direction: column;
}
.tabs .selector {
    display: flex;
    flex-direction: row;
    position: relative;
}
.tabs .selector button {
    flex: 1;
    margin: 0 0.5px;
    border-radius: 0;
    border: none;
    padding: 1em 0;
    background-color: transparent;
    box-shadow: 0px 0.25em 0 0 var(--color-info-background);
}
.tabs .selector .selected {
    box-shadow: 0px 0.25em 0 0 var(--special-bgcolor);
}